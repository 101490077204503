.App {
  text-align: center;
  padding: 0% 10%;
  padding-bottom: 3%;


}

#mainpage {
  margin-top: 15%;
}

body {
  background-color: #1e88e5;
  color: white;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
    background-image: none;
    border-radius: 12px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.svg {
  width: 60%;
  height: 30%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.svg2 {
  width: 80%;
  height: 50%;
  margin-top: 20%;
  margin-bottom: 5%;
}

@media only screen and (min-width: 800px) {
  .svg2 {
    width: 40%;
    height: 50%;
    margin-top: 3%;
    margin-bottom: 0%;
  }
  .svg {
    width: 30%;
    height: 50%;
    margin-top: 3%;
    margin-bottom: 3%;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#title {
  font-size: 2.5em;
  margin-bottom: 3%;
}

@media only screen and (min-width: 800px) {
  .info {
    width: 60%;
    margin: auto;
    margin-top: 3%;
  }

  #mainpage {
    margin-top: 5%;
  }
  
}

#listInstructions {
  text-align: left;
}

#final_calculation {
  border: 2px solid rgba(103, 58, 183, 0.85);
  width: 300px;
  margin: auto;
}

.info {
  margin-top: 4%;
  text-align:center justify;
}

footer {
  border-top: 1px solid white;
  margin-top: 15%;
  padding-top: 4%;
}


#sharingMenu {
  margin-top: 6%;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-bottom: 3%;
}
